import styled from "@emotion/styled";
import { graphql } from "gatsby";
import React, { useState } from "react";
import tw from "twin.macro";
import { Layout } from "../components/Layout";
import Play from "../images/play.svg";
import { Responsive } from "../utils/Responsive";
import { theme } from "../utils/theme";
import { DesktopCarousel, MobileCarousel } from "../components/Carousel";
import Metadata from "../components/Metadata";

const Landing = ({ data }) => {
    const [isCarouselOpen, setCarouselOpen] = useState<boolean>(true);
    const [selectedImageIdx, setSelectedImageIdx] = useState<number>(0);

    const mobileImages = data.contentfulLandingPage.mobileImages;
    const desktopImages = data.contentfulLandingPage.desktopImages;

    return (
        <Layout>
            <Metadata
                title={undefined}
                desc={data.contentfulLandingPage.description.description}
            />
            <StyledLanding>
                <div
                    tw="relative pointer-events-auto z-0"
                    onClick={() => setCarouselOpen(true)}
                    style={{
                        cursor: `url(${Play}) 50 50, auto`,
                    }}
                >
                    <Responsive
                        sm={
                            <MobileCarousel
                                {...{
                                    mobileImages,
                                    selectedImageIdx,
                                    setSelectedImageIdx,
                                }}
                            />
                        }
                        md={
                            isCarouselOpen && (
                                <DesktopCarousel
                                    {...{
                                        desktopImages,
                                        selectedImageIdx,
                                        setSelectedImageIdx,
                                    }}
                                />
                            )
                        }
                    />
                </div>
            </StyledLanding>
            <div
                tw="w-full h-full fixed inset-0"
                style={{ zIndex: -2, background: theme.colors.pistacio }}
            />
        </Layout>
    );
};

export default Landing;

const StyledLanding = styled.div`
    ${tw`relative grid w-full h-full z-10 pointer-events-none`};
    grid-template-rows: 1fr auto;
`;

export const query = graphql`
    query LandingPageQuery {
        contentfulLandingPage {
            id
            title
            description {
                description
            }
            mobileImages {
                description
                id
                resize(width: 700) {
                    src
                }
            }
            desktopImages {
                id
                images {
                    description
                    resize(width: 1800) {
                        src
                    }
                }
            }
        }
    }
`;
